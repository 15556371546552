import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { MediaQueriesContext, YStack, mobileMediaQueries, tokens, useMqSelect } from '@fhs/ui';
import { NutritionalInformation } from '@fhs/ui/src/components/nutritional-information';
import { SodiumDisclaimer } from '@fhs/ui/src/components/nutritional-information/sodium-disclaimer';
import { useStore } from '@fhs-legacy/frontend/src/state/store/hooks';

import { useComboSelectionContext } from '../../../state/combo-selection';
import { useMenuPicker } from '../../../state/menu-picker';
import { CustomizationGroup } from '../../customization-group';
import {
  ListItemGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
} from '../../list-item-group-section';
import { PickerAspectOptionSelector } from '../../picker-aspect-option-selector';
import { ProductDetailLayout } from '../../product-detail-layout';
import { ProductNameAndImage } from '../../product-name-and-image';

const enabledStatesForSodiumDisclaimer = ['PA', 'NY', 'FL'];

type NutritionalInformationProps = {
  isShowingNutrition: boolean;
  setIsShowingNutrition: (isShowingNutrition: boolean) => void;
  showSodiumDisclaimer: boolean;
};

const NutritionalInformationTile = ({
  setIsShowingNutrition,
  showSodiumDisclaimer,
}: Omit<NutritionalInformationProps, 'isShowingNutrition'>) => {
  return (
    <View>
      <ListItemGroup
        items={[
          {
            render: ({ isFirstItem, isLastItem }) => (
              <ListItemLinkPressable
                titleFontWeight="normal"
                minHeightStyle="content"
                isFirstItem={isFirstItem}
                isLastItem={isLastItem}
                title="Nutritional Information"
                onPress={() => setIsShowingNutrition(true)}
              />
            ),
          },
        ]}
      />
      {showSodiumDisclaimer && <SodiumDisclaimer />}
    </View>
  );
};

export const ItemDetailsContent = ({ selectedPickerId = '' }) => {
  const [isShowingNutrition, setIsShowingNutrition] = useState(false);

  const menuPicker = useMenuPicker();
  const requiresPickAspectOptionSelection = menuPicker.query.data?.pickerAspect.options.length > 1;

  const { setCustomizationSelections } = menuPicker;
  const { selectedPickers } = useComboSelectionContext();

  const selectedPicker = selectedPickers[selectedPickerId];

  // Auto-fill picker selections with previous selected picker customization
  useEffect(() => {
    if (selectedPicker) {
      setCustomizationSelections(selectedPicker.customizationSelections);
    }
  }, [selectedPicker, setCustomizationSelections]);

  const customizationGroups =
    menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups ?? [];

  const hasCustomizations = customizationGroups.length > 0;

  const baseCaloriesDisplay = menuPicker.selectedPickerAspectOption?.item?.baseCalories
    ? `${menuPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`
    : null;

  const hasCustomizationsContent = requiresPickAspectOptionSelection || hasCustomizations;
  const asHero = useMqSelect({ $gteDesktop: true }, !requiresPickAspectOptionSelection);

  const { store } = useStore();
  // TODO(simplified-restaurant): refactor to move this logic to the BFF
  const showSodiumDisclaimer =
    store.physicalAddress.stateProvinceShort &&
    enabledStatesForSodiumDisclaimer.includes(
      store.physicalAddress.stateProvinceShort.toUpperCase()
    );
  return (
    <>
      <MediaQueriesContext.Provider value={mobileMediaQueries}>
        <View style={{ flex: 9 }}>
          <ProductDetailLayout
            nameAndImageContent={
              <ProductNameAndImage
                displayName={menuPicker.displayName}
                description={menuPicker.query.data?.description}
                variant={menuPicker.selectedPickerAspectOption?.displayName}
                cals={baseCaloriesDisplay}
                asHero={asHero}
                imageUrl={menuPicker.query.data?.image.asset.uri}
                hasCustomizationsContent={hasCustomizationsContent}
                showSodiumDisclaimer={showSodiumDisclaimer}
                heroNutritionalInformationTile={
                  !hasCustomizationsContent && (
                    <NutritionalInformationTile
                      setIsShowingNutrition={setIsShowingNutrition}
                      showSodiumDisclaimer={showSodiumDisclaimer}
                    />
                  )
                }
              />
            }
            customizationContent={
              hasCustomizationsContent && (
                <YStack style={styles.customizationContent}>
                  {requiresPickAspectOptionSelection && (
                    <ListItemGroupSection
                      heading={menuPicker.query.data?.pickerAspect?.displayName}
                    >
                      <PickerAspectOptionSelector
                        value={menuPicker.selectedPickerAspectOptionId}
                        onChange={selection => {
                          menuPicker.setSelectedPickerAspectOptionId(selection);
                          menuPicker.setDraftCustomizationSelections({});
                        }}
                        options={menuPicker.query.data?.pickerAspect?.options.map(opt => ({
                          value: opt.id,
                          title: opt.displayName,
                          subtitle: opt.description,
                          image: opt.image,
                          disabled: !opt.item.isAvailable,
                        }))}
                      />
                    </ListItemGroupSection>
                  )}
                  {hasCustomizations &&
                    customizationGroups.map((customizationGroup, index) => {
                      return (
                        <CustomizationGroup
                          customizationSelections={menuPicker.customizationSelections}
                          shouldSaveDetailedCustomization
                          setCustomizationSelections={newSelections => {
                            menuPicker.setCustomizationSelections(newSelections);
                            menuPicker.setDraftCustomizationSelections(newSelections);
                          }}
                          key={customizationGroup.displayGroup.id ?? index}
                          customizationGroup={customizationGroup}
                        />
                      );
                    })}
                  <View style={styles.divider} />
                  <NutritionalInformationTile
                    setIsShowingNutrition={setIsShowingNutrition}
                    showSodiumDisclaimer={showSodiumDisclaimer}
                  />
                </YStack>
              )
            }
          />
        </View>
      </MediaQueriesContext.Provider>
      <NutritionalInformation
        nutritionFacts={{
          title: menuPicker.selectedPickerAspectOption?.item?.displayName,
          image: menuPicker.selectedPickerAspectOption?.item?.image,
          // @ts-ignore
          facts: menuPicker.selectedPickerAspectOption?.item?.nutrition ?? {},
        }}
        isVisible={isShowingNutrition}
        onClose={() => setIsShowingNutrition(false)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  customizationContent: {
    paddingHorizontal: 16,
    gap: 24,
  },
  divider: {
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
});
